const customSelectStyle = {
  option: (base, state) => ({
    ...base,
    backgroundColor: state?.isSelected
      ? '#0d122a'
      : state?.isFocused
      ? '#0d122a'
      : '#f7f8fa',
    color: state?.isSelected ? '#fff' : state?.isFocused ? '#fff' : '#333',
    ':active': {
      ...base[':active'],
      backgroundColor: state?.isSelected ? '#0d122a' : '#f7f8fa',
    },
    ':hover': {
      backgroundColor: '#00e78e',
      color: '#fff',
    },
  }),
};

const detectMobileBrowser = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

const getRadianAngle = (degreeValue) => (degreeValue * Math.PI) / 180;

const canvasPreview = async (
  image,
  crop,
  scale = 1,
  fileType = 'jpeg',
  outputWidthInPx,
  outputHeightInPx,
) => {
  const canvas = document?.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;

  // Displayed image x and y in respect to scale
  const scaleX = crop.x - (image.width - image.width * scale) / 2;
  const scaleY = crop.y - (image.height - image.height * scale) / 2;

  // Original image x and y
  const originalCropX = (image.naturalWidth * scaleX) / (image.width * scale);
  const originalCropY = (image.naturalHeight * scaleY) / (image.height * scale);

  // Original image width and height
  const originalCropWidth =
    (crop.width * image.naturalWidth) / (image.width * scale);
  const originalCropHeight =
    (crop.height * image.naturalHeight) / (image.height * scale);

  const originalCropLength = Math.min(originalCropWidth, originalCropHeight);
  let actualImageWidth = originalCropLength;
  let actualImageHeight = originalCropLength;

  if (outputWidthInPx) {
    actualImageWidth = outputWidthInPx;
  }
  if (outputHeightInPx) {
    actualImageHeight = outputHeightInPx;
  }

  ctx.drawImage(
    image,
    originalCropX,
    originalCropY,
    originalCropLength,
    originalCropLength,
    0,
    0,
    actualImageWidth,
    actualImageHeight,
  );

  const ImageData = ctx.getImageData(0, 0, actualImageWidth, actualImageHeight);

  const croppedCanvas = document?.createElement('canvas');
  const croppedCtx = croppedCanvas.getContext('2d');

  croppedCanvas.width = ImageData.width;
  croppedCanvas.height = ImageData.height;

  croppedCtx.putImageData(ImageData, 0, 0);

  return new Promise((resolve) => {
    croppedCanvas.toBlob(
      (file) => {
        resolve(file);
      },
      `image/${fileType}`,
      1,
    );
  });
};

const getCroppedImg = async (imageSrc, pixelCrop,rotation = 0, outputWidth = 3000, outputHeight = 3000) => {
  const image = await createImage(imageSrc);
  const canvas = document?.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image?.width, image?.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image?.width * 0.5,
    safeArea / 2 - image?.height * 0.5,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image?.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image?.height * 0.5 - pixelCrop.y),
  );

  // Resize the cropped area to the final output dimensions (3000x3000)
  const finalCanvas = document.createElement('canvas');
  const finalCtx = finalCanvas.getContext('2d');
  finalCanvas.width = outputWidth;
  finalCanvas.height = outputHeight;

  // Draw the cropped area on the final canvas, scaled to the desired output size
  finalCtx.drawImage(canvas, 0, 0, outputWidth, outputHeight);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob(
      (file) => {
        resolve(file);
      },
      'image/jpeg',
      1,
    );
  });
};

const readFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

const readFileAsText = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsText(file);
  });

export {
  customSelectStyle,
  detectMobileBrowser,
  getCroppedImg,
  readFile,
  readFileAsText,
  canvasPreview,
};
