import React, { useContext, useRef, useEffect, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { gtmEvents } from 'utils/gtm';
import Cookies from 'lib/js-cookie';
import { supportedLanguages, translate as t } from 'utils/helpers';
import UserContext from 'context/UserContext';
import { podcastEpisodePlay, audioSeriesEpisodePlay } from 'apis/episode';
import AudioPopup from './AudioPopup';

const PodcastPlayer = ({ lang }) => {
  const [userData, setUserData] = useContext(UserContext);
  const [modalShow, setModalShow] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [playLoadSrc, setPlayLoadSrc] = useState('/other-icons/loading.svg');
  const [coverLoadSrc, setCoverLoadSrc] = useState(false);
  const [currentTime, setAudioCurrentTime] = useState(0);
  const [isOffline, setIsOffline] = useState(false);
  const spanRef = useRef();
  const hostedByTextRef = useRef();
  const stickyTextRef = useRef();
  const audioRef = useRef();

  let playerInterval = {
    listenInverval: 0,
    needApiToHit: true,
    interval: null,
  };

  useEffect(() => {
    if (userData?.playerData) {
      setUserData((prevState) => ({
        ...prevState,
        audioPlayStatus: false,
        isOnRefresh: true,
        showPlayer: true,
        episodeDetail: userData.playerData,
      }));
    }
  }, [userData?.playerData]);

  useEffect(() => {
    spanRef.current.className =
      spanRef?.current?.offsetWidth > 160 ? 'running-text' : 'title-text';
    hostedByTextRef.current.className =
      hostedByTextRef?.current?.offsetWidth > 160
        ? 'running-text'
        : 'title-text';
    stickyTextRef.current.className =
      hostedByTextRef?.current?.offsetWidth > 160 ? 'sticky-text' : '';
    spanRef.current.className =
      window?.innerWidth < 768 && spanRef?.current?.offsetWidth > 140
        ? 'running-text'
        : 'title-text';
    hostedByTextRef.current.className =
      window?.innerWidth < 768 && hostedByTextRef?.current?.offsetWidth > 120
        ? 'running-text'
        : 'title-text';
    stickyTextRef.current.className =
      window?.innerWidth < 768 && hostedByTextRef?.current?.offsetWidth > 120
        ? 'sticky-text'
        : '';
  });
  useEffect(() => {
    if (userData?.audioPlayStatus) {
      audioRef?.current?.audio?.current?.play();
      gtmEvents.playerStatus({
        status: 'playing',
        episodeData: { ...userData?.episodeDetail },
      });
    } else {
      audioRef?.current?.audio?.current?.pause();
      gtmEvents.playerStatus({
        status: 'stopped',
        episodeData: { ...userData?.episodeDetail },
      });
    }
  }, [userData?.audioPlayStatus]);
  useEffect(() => {
    if (!userData?.showPlayer) setModalShow(false);
  }, [userData?.showPlayer]);
  useEffect(() => {
    if (userData?.episodeDetail?.url) {
      document?.body?.classList?.add('audio-player-active');
      if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
        Cookies.remove('playerData');
        Cookies.set('playerData', JSON.stringify(userData?.episodeDetail), {
          path: '/',
        });
      } else {
        Cookies.remove('playerData');
        Cookies.set('playerData', JSON.stringify(userData?.episodeDetail), {
          path: '/',
          domain: '.mumbler.io',
        });
      }

      sessionStorage.removeItem('lastListenTime');
      sessionStorage.removeItem('started');
      sessionStorage.removeItem('apiHitted');
      // setPlayLoadSrc('/other-icons/loading.svg')
      setPlayLoadSrc('/other-icons/pause-icon.svg');
      setCoverLoadSrc(false);
      if (playerInterval.interval) {
        clearInterval(playerInterval?.interval);
      }
    }
  }, [userData?.episodeDetail]);

  const openPlayerPopup = () => {
    setAudioCurrentTime(audioRef?.current?.audio.current?.currentTime);
    setModalShow(!modalShow);
  };
  const audioPlayClickHandler = () => {
    if (audioRef?.current?.audio?.current?.readyState === 4) {
      setPlayLoadSrc('/other-icons/pause-icon.svg');
    } else {
      setPlayLoadSrc('/other-icons/loading.svg');
    }
    setUserData((prevState) => ({
      ...prevState,
      audioPlayStatus: true,
    }));
    if (!sessionStorage.getItem('apiHitted')) resumeListening();
  };
  const audioPauseClickHandler = () => {
    if (playerInterval?.interval) clearInterval(playerInterval?.interval);
    audioRef?.current?.audio?.current?.pause();
    sessionStorage.setItem('lastListenTime', playerInterval?.listenInverval);
    setUserData((prevState) => ({
      ...prevState,
      audioPlayStatus: false,
    }));
  };

  const jumpToSpecificTime = (time) => {
    audioRef.current.audio.current.currentTime = time;
  };

  const prevNextClickHandler = (clickedBtn) => {
    if (playerInterval.interval) {
      clearInterval(playerInterval.interval);
    }
    sessionStorage.removeItem('started');
    sessionStorage.removeItem('lastListenTime');
    sessionStorage.removeItem('apiHitted');
    setUserData((prevState) => ({
      ...prevState,
      isOnRefresh: false,
      audioPlayTime: audioRef?.current?.audio.current?.currentTime,
      reqForBtn: clickedBtn + new Date().getTime(),
    }));
    setAudioCurrentTime(0);
  };

  const onPlayHandler = () => {
    window?.addEventListener('offline', () => {
      let offlinePrevTime = 0;
      const detectOffline = setInterval(() => {
        if (
          offlinePrevTime === audioRef?.current?.audio?.current?.currentTime
        ) {
          if (playerInterval.interval) {
            clearInterval(detectOffline);
          }
          setIsOffline(true);
          setPlayLoadSrc('/other-icons/play-icon.svg');
          audioRef.current?.audio?.current?.pause();
        }
        offlinePrevTime = audioRef?.current?.audio?.current?.currentTime;
      }, 1000);
    });
    window?.addEventListener('online', () => {
      if (userData?.audioPlayStatus === true) {
        audioRef?.current?.audio?.current?.play();
      }
      setIsOffline(false);
    });
  };

  const resumeListening = () => {
    if (sessionStorage.getItem('lastListenTime')) {
      playerInterval.listenInverval = parseInt(
        sessionStorage.getItem('lastListenTime'),
      );
    }
    if (userData?.isOnRefresh && userData?.audioPlayStatus === true) {
      sessionStorage.setItem('started', true);
    }
    if (sessionStorage.getItem('started')) {
      playerInterval.listenInverval = parseInt(
        sessionStorage.getItem('lastListenTime'),
      );
      if (intervalId) {
        clearInterval(intervalId);
      }
      const idInterval = setInterval(() => {
        playerInterval.listenInverval += 1;
        if (sessionStorage.getItem('apiHitted'))
          if (playerInterval.interval) {
            clearInterval(playerInterval?.interval);
          }
        if (
            playerInterval?.listenInverval > 60 &&
            !sessionStorage.getItem('apiHitted')
        ) {
          if (playerInterval.interval) {
            clearInterval(playerInterval?.interval);
          }
          increaseEpisodePlayCount();
        }
      }, 1000);
      playerInterval.interval = idInterval;
      setIntervalId(idInterval);
    }
  };

  const increaseEpisodePlayCount = async () => {
    const { id, audio_type } = userData?.episodeDetail;
    let response;
    if (audio_type === 'podcast') response = await podcastEpisodePlay(id);
    else response = await audioSeriesEpisodePlay(id);
    if (response?.status === 200) {
      clearInterval(playerInterval?.interval);
      sessionStorage.setItem('apiHitted', true);
    }
  };

  const onCanPlayHandler = () => {
    if (userData?.isOnRefresh) sessionStorage.setItem('started', true);
    if (!sessionStorage.getItem('started') && userData?.audioPlayStatus) {
      sessionStorage.setItem('started', true);
      setPlayLoadSrc('/other-icons/pause-icon.svg');
      setTimeout(() => {
        setCoverLoadSrc(true);
      }, 2000);
      if (intervalId) {
        clearInterval(intervalId);
      }
      const idInterval = setInterval(() => {
        playerInterval.listenInverval += 1;
        if (audioRef?.current?.audio?.current?.paused) {
          sessionStorage.setItem(
              'lastListenTime',
              playerInterval?.listenInverval,
          );
          if (playerInterval.interval) {
            clearInterval(playerInterval?.interval);
          }
        }
        if (playerInterval?.listenInverval > 60) {
          if (playerInterval.interval) {
            clearInterval(playerInterval?.interval);
          }
          increaseEpisodePlayCount();
        }
      }, 1000);
      playerInterval.interval = idInterval;
      setIntervalId(idInterval);
    }
  };

  const handleOnError = () => {
    audioRef?.current?.audio?.current?.pause();
    setPlayLoadSrc('/other-icons/loading.svg');
  };

  const handleOnEnded = () => {
    setUserData((prevState) => ({
      ...prevState,
      audioPlayTime: 0,
      reqForBtn: `next${new Date().getTime()}`,
      hasEnded: true,
    }));
    setAudioCurrentTime(0);
  };
  const closePlayer = () => {
    document?.body?.classList?.remove('audio-player-active');
    if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
      Cookies.remove('playerData', { path: '/' });
    } else {
      Cookies.remove('playerData', { path: '/', domain: '.mumbler.io' });
    }
    setUserData((prevState) => ({
      ...prevState,
      showPlayer: false,
      audioPlayStatus: false,
    }));
    setModalShow(false);
  };

  return (
    <div
      className="audio-player-wrap"
      style={{
        zIndex: 1000,
        display: userData?.showPlayer ? 'block' : 'none',
      }}>
      <AudioPlayer
        src={userData?.episodeDetail?.url}
        ref={audioRef}
        onPlayError={(e) => console.log('playerror', e)}
        onError={(e) => handleOnError(e)}
        onPlay={onPlayHandler}
        onCanPlay={onCanPlayHandler}
        onEnded={handleOnEnded}
        // onEnded={closePlayer}
        onAbort={(e) => console.log('abort', e)}
        onClickPrevious={() => prevNextClickHandler('prev')}
        onClickNext={() => prevNextClickHandler('next')}
        showSkipControls
        showJumpControls={false}
        header={
          <div
            className="audioplayer-header"
            data-network={isOffline ? 'Network issue' : ''}>
            {coverLoadSrc || userData?.isOnRefresh ? (
              <img
                className="cover-art"
                src={userData?.episodeDetail?.cover}
                alt=""
                onClick={openPlayerPopup}
              />
            ) : (
              <img
                className="cover-art"
                src="/other-icons/loading.svg"
                alt=""
              />
            )}
            <div className="episode-details">
              <p
                className="heading"
                title={userData?.episodeDetail?.episode_name}>
                <Link
                  href={userData?.episodeDetail?.episode_redirect_url || '#'}>
                  <a>
                    <span ref={spanRef}>
                      {userData?.episodeDetail?.episode_name}
                    </span>
                  </a>
                </Link>
              </p>
              <p className="author-name" title="Carolina Moreno">
                <span ref={stickyTextRef}>{t(lang, 'podcast')('by')} </span>
                <Link href={userData?.episodeDetail?.creator_domain_url || '#'}>
                  <a>
                    <span ref={hostedByTextRef}>
                      {userData?.episodeDetail?.hosted_by}
                    </span>
                  </a>
                </Link>
              </p>
            </div>
          </div>
        }
        customAdditionalControls={[
          <img
            onClick={closePlayer}
            className="sticky-player-close"
            src="/other-icons/close-icon.svg"
            alt="Close"
          />,
        ]}
        customIcons={{
          play: (
            <img
              className="player-play-icon"
              src="/other-icons/play-icon.svg"
              width="40"
              height="40"
              alt="Play"
              id="audioPlayerPlayButton"
              onClick={audioPlayClickHandler}
            />
          ),
          pause: (
            <img
              className="player-pause-icon"
              src={playLoadSrc}
              width="40"
              height="40"
              alt="Pause"
              id="audioPlayerPauseButton"
              onClick={audioPauseClickHandler}
            />
          ),
          previous: (
            <img
              src="/other-icons/previous-icon.svg"
              width="16"
              height="16"
              alt="Previous"
            />
          ),
          next: (
            <img
              src="/other-icons/next-icon.svg"
              width="16"
              height="16"
              alt="Previous"
            />
          ),
          volume: (
            <img
              src="/other-icons/volume-icon.svg"
              width="16"
              height="16"
              alt="Volume"
            />
          ),
          volumeMute: (
            <img
              src="/other-icons/volume-icon.svg"
              width="16"
              height="16"
              alt="Volume"
            />
          ),
        }}
      />
      <AudioPopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        episodeData={userData?.episodeDetail}
        currentTime={currentTime}
        jumpToSpecificTime={jumpToSpecificTime}
      />
    </div>
  );
};

PodcastPlayer.propTypes = {
  lang: PropTypes.oneOf(supportedLanguages),
};

export default PodcastPlayer;
